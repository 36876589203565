import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Partners from "../../../static/images/icons/web/icon20.svg"
import { FaFilePdf } from "react-icons/fa"

const PartnerProgramRecursos = () => {
  const data = useStaticQuery(graphql`
    query ImagesPartnerProgramRecursos {
      image: allFile(
        filter: { relativeDirectory: { eq: "partners" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Recursos del  Partner Program | Qualoom</title>
        <meta name="title" content="Recursos del  Partner Program | Qualoom"/>
        <meta name="description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todos los recursos disponibles"/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/partner-program/recursos/"/>
        <link rel="canonical" href="https://www.qualoom.es/partner-program/recursos/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/partner-program/recursos/"/>
        <meta property="og:title" content="Recursos del  Partner Program | Qualoom"/>
        <meta property="og:description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todos los recursos disponibles"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/partner-program/recursos/"/>
        <meta property="twitter:title" content="Recursos del  Partner Program | Qualoom"/>
        <meta property="twitter:description" content="Ya puedes formar parte de esta comunidad de Partners y beneficiarte de todos los recursos disponibles"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/partner-program/recursos/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Partner Program - Recursos", "item": {"@id": "https://www.qualoom.es/partner-program/recursos/", "name": "Partner Program - Recursos"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-partner">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Recursos del Partner Program</h1>
              <p>Descubre todos los recursos comerciales y de marketing disponibles para todos los miembros del Partner Program, y nuestro Código ético que deben seguir todos los miembros</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Recursos a disposición de los Partners</h2>
          <p>Al formar parte de nuestro Partner Program <b>tendrás a tu disposición diferentes recursos con explicaciones detalladas sobre diferentes aspectos esenciales de Qualoom Expertise Technology</b>, como los servicios que ofrecemos, nuestros casos de éxito y el uso correcto de nuestra marca en acciones de marketing.</p>
          <p>El <b>Código ético</b> es un documento donde se recopilan todos <b>los criterios, normas y valores</b> que conforman la ideología de Qualoom Expertise Technology, y <b>que deben ser asumidos por todos los miembros del Partner Program.</b></p><br/>
          <div className="resources-pdf">
            <p>Recursos comerciales</p>
            <ul>
              <li>Servicios ofrecidos <a href="/documents/partner-program/Qualoom Expertise Technology - Servicios ofrecidos.pdf" target="_blank"><FaFilePdf size={20}/>Descargar PDF</a></li>
              <li>Casos de éxito <a href="/documents/partner-program/Qualoom Expertise Technology - Casos de éxito.pdf" target="_blank"><FaFilePdf size={20}/>Descargar PDF</a></li>
            </ul>
          </div>
          <div className="resources-pdf">
            <p>Recursos de marketing</p>
            <ul><li>Brandbook <a href="/documents/partner-program/Qualoom Expertise Technology - Brandbook.pdf" target="_blank"><FaFilePdf size={20}/>Descargar PDF</a></li></ul>
          </div>
          <div className="resources-pdf">
            <p>Código ético</p>
            <ul><li>Código ético y de conducta <a href="/documents/partner-program/Qualoom Expertise Technology - Partner Program. Código ético.pdf" target="_blank"><FaFilePdf size={20}/>Descargar PDF</a></li></ul>
          </div>
          <div className="partner-program2">
            <img src={Partners} alt="Partner Program"></img>
            <div>
              <h3>¿Aún no eres miembro de nuestro Partner Program?</h3><a href="/partner-program/inscripcion/" className="servicios-button">Inscríbete aquí</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerProgramRecursos
